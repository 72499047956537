import React from 'react';
// import { useLocation } from '@reach/router';
import '../styles/global.css';
import Header from './header';
import Footer from './footer';
import BackToTop from './BackToTop';
import { KeyDatesSideBar } from './KeyDates';

export default function Layout({ children }) {
  // const location = useLocation();

  // const customPages = ["/","/congress-venue/","/visit-victoria/"];
  // const shouldHaveClasses = customPages.includes(location.pathname);

  return (
    <>
      <Header />
      <main className="xl:container mx-auto flex flex-col xl:flex-row lg:gap-6">
        <div className={`relative w-full xl:w-9/12 py-12 md:py-16 lg:py-20 space-y-10`}>{children}</div>
        <div className="w-full xl:w-3/12 bg-slate-200 py-12 md:py-16 lg:py-20 px-5">
          <div className="sticky top-10">
            <KeyDatesSideBar />
          </div>
        </div>
      </main>
      <Footer />
      <BackToTop />
    </>
  );
}
