import React from "react";
import { Link } from "gatsby";

const NavItem = ({ to, children }) => (
  <Link className="hover:text-blue-500" to={to}>
    {children}
  </Link>
);

export default NavItem;
