import useGoogleSheets from 'use-google-sheets';

const useGoogleSheetData = (sheetId, pageId) => {

  const apiKey = 'AIzaSyD7_vDWpGYiSRBVai_2_Aa1QPmSdqIjrzQ';

  const { data, loading, error } = useGoogleSheets({
    apiKey: apiKey,
    sheetId: sheetId,
    sheetsOptions: [{ id: pageId }],
  });

  return { data, loading, error };
};

export default useGoogleSheetData;
